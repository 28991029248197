<template>
     <div class="container mt-4">
     <h4>Payment UUID: {{ uuid }}</h4>
        <div v-if="status != 5">
            <div class="content d-flex justify-content-center mt-4">
                <div class="contrainer">
                    <DualRingLoader :size="200" :color="'#54f1d2'" />
                </div>
            </div>
            <div class="content container d-flex justify-content-center mt-4">
                <div class="container">
                    <h1 class="text-center">{{ 'payments.processing' | trans}}...</h1>
                </div>
            </div>
            <!-- Mock Payment Completed Button -->
            <div class="content container d-flex justify-content-center mt-4">
                <button @click="mockPaymentCompleted" class="btn btn-primary">
                    {{ 'payments.mock_payment_completed' | trans}}
                </button>
            </div>
        </div>
        
        <div v-else>
             <div class="content d-flex justify-content-center mt-4">
                <div class="contrainer mt-4">
                <span style="color: green;">
                    <h1><font-awesome-icon icon="fa-solid fa-check-circle fa-10x"/></h1>
                </span>
                </div>
            </div>
            <div class="content container d-flex justify-content-center mt-4">
                <div class="container mt-4">
                    <h1 class="text-center">{{ 'payments.STATUS_COMPLETED' | trans}}</h1>
                    <p class="text-center">{{ 'payments.redirecting_to_package' | trans}}...</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DualRingLoader } from 'vue-spinners-css';

export default {
    data: function () {
        return {
            payment: null,
            packageUuid: null,
            status: 1,
        }
    },
    components: {
        DualRingLoader,
    },
    props: {
        uuid: null,
    },
    methods: {
        loadPayment: function() {
            self = this;
            axios.get(this.$root.route('ajax.payments.show', this.uuid))
            .then(function (response) {
                self.payment = response.data.data;

                // Get first package UUID if available
                if (self.payment.packages && self.payment.packages.length > 0) {
                    self.packageUuid = self.payment.packages[0].uuid;
                }

                if (self.payment.status != 5) {
                    setTimeout(() => self.loadPayment(), 5000);
                } else {
                    self.status = 5;
                    self.redirectToPackage();
                }
            })
        },

        redirectToPackage() {
            if (this.packageUuid) {
                setTimeout(() => {
                    this.$router.push({ path: `/packages/${this.packageUuid}` });
                }, 3000);
            }
        },

        mockPaymentCompleted() {
            axios.put(this.$root.route('ajax.payments.mockPaymentCompleted', this.uuid))
                .then(() => {
                    this.status = 5;
                })
                .catch(error => {
                    console.error('Error completing mock payment:', error);
                });
        }
    },
    mounted() {
        this.loadPayment();
    }
}
</script>