<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'app.dashboard' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'app.dashboard' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <div>
            <div class="row">
                <div class="col-lg-3 col-md-6 mb-2">
                    <parcels-status-component :status="'awaiting'" />
                </div>
                <div class="col-lg-3 col-md-6 mb-2">
                    <parcels-status-component :status="'on_way'" />
                </div>
                <div class="col-lg-3 col-md-6 mb-2">
                    <parcels-status-component :status="'pickup'" />
                </div>
                <div class="col-lg-3 col-md-6 mb-2">
                    <parcels-status-component :status="'takeout'" />
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-4 col-sm-12">
                    <balance-component />
                    <package-amount-component />
                </div>
                <div class="col-lg-4 col-sm-12">
                    <top-services-component />
                    <payments-by-month-component />
                </div>
                <div class="col-lg-4 col-sm-12">
                    <last-package-component />
                    <calendar-component />
                </div>
            </div>
        </div>
    </div>      
</template>

<script>
import BalanceComponent from './widgets/BalanceComponent.vue';
import CalendarComponent from './widgets/CalendarComponent.vue';
import LastPackageComponent from './widgets/LastPackageComponent.vue';
import PackageAmountComponent from './widgets/PackageAmountComponent.vue';
import ParcelsStatusComponent from './widgets/ParcelsStatusComponent.vue';
import PaymentsByMonthComponent from './widgets/PaymentsByMonthComponent.vue';
import TopServicesComponent from './widgets/TopServicesComponent.vue';

export default {
    components: {
        BalanceComponent,
        CalendarComponent,
        LastPackageComponent,
        PackageAmountComponent,
        ParcelsStatusComponent,
        PaymentsByMonthComponent,
        TopServicesComponent,
    },

}

</script>

<style>

</style>
