<template>
    <div class="">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'packages.create' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'packages-index'})">{{ 'packages.packages' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'packages.create' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!--Service Type-->
        <div class="content" v-if="progress == 0">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_service_type' | trans }}</h3>
            <div class="row text-center">
                <div class="col-md-3 col-sm-6 mb-2">
                    <div 
                        class="card p-4" 
                        :class="serviceType === 4 ? 'bg-info' : ''" 
                        @click="serviceType = 4"
                        style="cursor: pointer;"
                    >
                        <h4>{{ 'services.endToEnd' | trans }}</h4>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6 mb-2">
                    <div 
                        class="card p-4" 
                        :class="serviceType === 1 ? 'bg-info' : ''" 
                        @click="serviceType = 1"
                        style="cursor: pointer;"
                    >
                        <h4>{{ 'services.firstMile' | trans }}</h4>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6 mb-2 ">
                    <div 
                        class="card p-4" 
                        :class="serviceType === 2 ? 'bg-info' : ''" 
                        @click="serviceType = 2"
                        style="cursor: pointer;"
                    >
                        <h4>{{ 'services.lastMile' | trans }}</h4>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6 mb-2">
                    <div 
                        class="card p-4" 
                        :class="serviceType === 3 ? 'bg-info' : ''" 
                        @click="serviceType = 3"
                        style="cursor: pointer;"
                    >
                        <h4>{{ 'services.storage' | trans }}</h4>
                    </div>
                </div>
            </div>
            <!-- Service Type Description Card -->
            <div class="row mt-4" v-if="serviceType">
                <div class="col-12">
                    <div class="card bg-light p-4">
                        <h5 class="text-dark text-center">{{ serviceTypeTitle }}</h5>
                        <p class="text-muted">{{ serviceTypeDescription }}</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    
                </div>
                <div class="col-4 mt-4">
                    
                </div>
                <div class="col-4 mt-4">
                    <button type="button" v-if="serviceType != null" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progress = 1">{{ 'packages.next' | trans }}</button>
                    <button type="button" disabled v-else class="btn btn-warning btn-block mb-2 start-0" style="" >{{ 'packages.next' | trans }}</button>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="serviceType == 4">
                <package-step-end-to-end-component @progress="progressChange" @packageCreated="createHandler""></package-step-end-to-end-component>
            </div>
            <div v-if="serviceType == 1">
                <package-step-first-mile-component @progress="progressChange" @packageCreated="createHandler"></package-step-first-mile-component>
            </div>
            <div v-if="serviceType == 2">
                <package-step-last-mile-component @progress="progressChange" @packageCreated="createHandler"></package-step-last-mile-component>
            </div>
            <div v-if="serviceType == 3">
                <package-step-storage-component @progress="progressChange" @packageCreated="createHandler"></package-step-storage-component>
            </div>
        </div>
    </div>
</template>


<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import PackageStepEndToEndComponent from './PackageStepEndToEndComponent.vue';
import PackageStepFirstMileComponent from './PackageStepFirstMileComponent.vue';
import PackageStepLastMileComponent from './PackageStepLastMileComponent.vue';
import PackageStepStorageComponent from './PackageStepStorageComponent.vue';

export default {
    components: {
        PackageStepEndToEndComponent,
        PackageStepFirstMileComponent,
        PackageStepLastMileComponent,
        PackageStepStorageComponent,
    },
    data: function () {
        return {
            progress: 0,
            serviceType: null,
        }
    },
    props: {

    },
    computed: {
        serviceTypeTitle() {
            const titles = {
                1: 'services.firstMile',
                2: 'services.lastMile',
                3: 'services.storage',
                4: 'services.endToEnd',
            };
            return titles[this.serviceType] ? this.$options.filters.trans(titles[this.serviceType]) : '';
        },
        serviceTypeDescription() {
            const descriptions = {
                1: 'services.firstMileExplenation',
                2: 'services.lastMileExplenation',
                3: 'services.storageExplenation',
                4: 'services.endToEndExplenation',
            };
            return descriptions[this.serviceType] ? this.$options.filters.trans(descriptions[this.serviceType]) : '';
        },
    },
    methods: {
       progressChange: function(value) {
           this.progress = value;
       },
       createHandler (paymentType, packageObject) {
            switch(paymentType) {
                case 1:
                    this.$router.push({ path: `/packages/${packageObject.uuid}` });
                    break;
                case 2:
                    window.location.href = packageObject.payment_link
                    break;
                case 3:
                    window.location.href = packageObject.payment_link
                    break;
                case 4:
                    this.$router.push({ path: `/packages/${packageObject.uuid}` });
                    break;
            }
       }
    },
    watch: {

    },
    mounted() {
        //this.loadServices();
    } 
}
</script>

<style>
#kg-suffix {
    position: absolute;
    left: 100%;
    top: 55%;
    color: #555;
    padding-left: 5px;
    font: inherit;
}
</style>