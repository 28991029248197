import Vue from 'vue';

import VueSelect from 'vue-select';
Vue.component('v-select', VueSelect);

// OLD VERSION OF BOOTSTRAP TO BE COMPATIBLE WITH APP
import 'bootstrap4/dist/css/bootstrap.css'

import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css';
Vue.use(VueLayers);

import VModal from 'vue-js-modal';
Vue.use(VModal);

window.Swal = require('sweetalert2');

import * as VueSpinnersCss from "vue-spinners-css";
Vue.use(VueSpinnersCss)

import UUID from "vue-uuid";
Vue.use(UUID);

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker);

import AppPage from './pages/AppPage.vue';
import SelectEntityCreateBusinessPage from './pages/select-entity/SelectEntityCreateBusinessPage.vue';
import SelectEntityCreatePersonalPage from './pages/select-entity/SelectEntityCreatePersonalPage.vue';

import Lang from './lang/index';

Vue.filter('trans', (...args) => {
    return Lang.get(...args);
});

Vue.route = require('./routes/laravel.js');
import routes from './routes';
import VueRouter from 'vue-router';
Vue.use(VueRouter)

const router = new VueRouter({
    routes: routes,
    mode: 'history'
})


// FontAwesome
import { library as fontAwesomeIconsLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/free-solid-svg-icons'

fontAwesomeIconsLibrary.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

var app = new Vue({
    el: '#app',
    router: router,
    components: {
        AppPage,
        SelectEntityCreateBusinessPage,
        SelectEntityCreatePersonalPage,
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
                return true;
            return false;
        },
        // Returns the localized array of months
        getMonthsArray: function() {
            var months = [];
            months.push(this.$options.filters.trans('app.january'));
            months.push(this.$options.filters.trans('app.february'));
            months.push(this.$options.filters.trans('app.march'));
            months.push(this.$options.filters.trans('app.april'));
            months.push(this.$options.filters.trans('app.may'));
            months.push(this.$options.filters.trans('app.june'));
            months.push(this.$options.filters.trans('app.july'));
            months.push(this.$options.filters.trans('app.august'));
            months.push(this.$options.filters.trans('app.september'));
            months.push(this.$options.filters.trans('app.october'));
            months.push(this.$options.filters.trans('app.november'));
            months.push(this.$options.filters.trans('app.december'));

            return months;
        },
        // This method returns month's position relative to current month
        changeMonthToRelative: function(position) {
            if (position > 11) {
                position = position - 12
            }
            return position;
        },
        route: Vue.route
    }
});