<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex flex-column justify-content-center mb-2">
                <h3 class="card-title mb-0">{{ header || 'app.search' | trans }}</h3>
            </div>
            <button type="button" class="btn btn-info ms-4" v-if="includeFilters" size="sm" @click="toggleFilters">{{ 'app.filters' | trans }}</button>
            <div class="ms-auto d-flex flex-column justify-content-center float-right">
                <ul class="pagination pagination-sm float-end mb-0">
                    <li class="page-item" v-if="meta.current_page !== 1"  v-bind:class="{'disabled': loading}">
                        <a class="page-link" v-on:click="prevPage()" href="javascript:void(0);">«</a>
                    </li>
                    <li v-if="!$root.isMobile()" v-for="index in pages" :key="index" v-bind:class="{'page-item':true, 'active':(index === meta.current_page), 'disabled': loading}">
                        <a v-on:click="page(index)" class="page-link" href="javascript:void(0);">{{index}}</a>
                    </li>
                    <template v-if="!$root.isMobile() && pages.length > 1">
                        <li  v-if="meta.last_page < 10" class="page-item" >
                            <div class="dropdown">
                                <button class="btn btn-info btn-sm dropdown-toggle page-item pagination-dropdown" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon icon="fa-solid fa-search"/>
                                </button>
                                <ul class="dropdown-menu page-dropdown" aria-labelledby="dropdownMenuButton">
                                    <li v-for="index in meta.last_page" :key="index">
                                        <a class="dropdown-item" href="javascript:void(0);" @click="page(index)">{{ index }}</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li v-else class="page-item">
                            <input min="1" :placeholder="meta.last_page" type="number" v-on:keyup.enter="page(pageSearch)" v-model="pageSearch" :max="meta.last_page" class="page-link text-center" style="width: 80px" />
                        </li>
                    </template>
                    <li class="page-item disabled" v-if="$root.isMobile()">
                        <a class="page-link" disabled>{{meta.current_page}} / {{meta.last_page}}</a>
                    </li>
                    <li class="page-item" v-if="meta.last_page != meta.current_page" v-bind:class="{'disabled': loading}">
                        <a class="page-link" v-on:click="nextPage()" href="javascript:void(0);">»</a>
                    </li>
                </ul>
            </div>

            <div class="col-12 px-0" v-show="filtersVisible">
                <div>
                    <slot :buildRequest="buildRequest" name="searchCollapse"></slot>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0 table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <slot name="labels"></slot>
                    </tr>
                </thead>
                <tbody v-if="includeCollapse" class="accordion" role="tablist">
                    <template v-for="(element, index) in data">
                        <tr @click="toggleRow(index)" class="m-1" role="tab" variant="info" >
                            <slot name="columns" v-bind:element="element" v-bind:index="index"></slot>
                        </tr>
                        <tr>
                            <td colspan="100%" class="p-0" style="border:0">
                                <div class="p-4" v-show="isRowVisible(index)" role="tabpanel">
                                    <slot name="collapse" v-bind:element="element" v-bind:index="index"></slot>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <template v-for="(element, index) in data">
                        <tr>
                            <slot name="columns" v-bind:element="element" v-bind:index="index"></slot>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <!-- /.card-body -->
        <div v-if="loading" class="overlay d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-if="!loading && data.length == 0" class="p-4 text-center">
            <h4>{{ 'app.content_empty' | trans }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            data: [],
            meta: {},
            loading: true,
            search: '',
            pageSearch: '',
            filtersVisible: false,
            visibleRows: []
        }
    },
    props: {
        model: null,
        header: null,
        params: {},
        includeCollapse: null,
        includeFilters: null,
    },
    methods: {
        load () {
            this.loading = true;
            let self = this;
            Object.assign(this.params, {'page': this.meta.current_page});

            axios.get(this.$root.route('ajax.' + this.model + '.index'), {
                params: this.params
            })
            .then(function (response) {
                self.data = response.data.data;
                self.meta = response.data.meta;
                if (self.data.length != 0 && self.data[0].created_at != null && self.data[0].updated_at != null) {
                    for (var i = 0; i< self.data.length; i++) {
                        var date = new Date(self.data[i].created_at);
                        self.data[i].created_at = date.toLocaleString();
                        date = new Date(self.data[i].updated_at);
                        self.data[i].updated_at = date.toLocaleString();
                    }
                }
                self.loading = false;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        nextPage () {
            this.meta.current_page++;
            this.load();
        },
        prevPage () {
            this.meta.current_page--;
            this.load();
        },
        page (index) {
            this.meta.current_page = index;
            this.load();
        },
        changeSearchStatus (status) {
            this.searchByStatus = status;
        },
        buildRequest (object) {
            Object.assign(this.params, { [object.name] : object.value });
            this.meta.current_page = 1;
            this.load();
        },
        toggleFilters() {
            this.filtersVisible = !this.filtersVisible;
        },
        toggleRow(index) {
            if (this.visibleRows.includes(index)) {
                this.visibleRows = this.visibleRows.filter(i => i !== index);
            } else {
                this.visibleRows.push(index);
            }
        },
        isRowVisible(index) {
            return this.visibleRows.includes(index);
        }
    },
    computed: {
        pages: function () {
            var arr = [];
            for(
                var i = (this.meta.current_page <= 5) ? 1 : this.meta.current_page - 5; 
                i <= ( (this.meta.current_page + 5 > this.meta.last_page) ? this.meta.last_page : (this.meta.current_page + 5) ); 
                i++
            ) {
                arr.push(i);
            }
            return arr;
        }
    },
    watch: {
        params: {
            deep: true,
            handler () {
                this.load();
            },
        },
        search: function() {
            this.load();
        },
        searchByStatus: {
            deep: true,
            handler () {
                this.meta.current_page = 1;
                this.load();

            },
        },
    },
    mounted () {
        if (!this.params)
            this.params = {};
        this.load();
    }
}
</script>
<style scoped lang="scss">
:deep(.dropdown-menu) {
    min-width: 4rem !important;
}
</style>