<template>
    <div class="" v-if="package">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'packages.package' | trans }}</h1>
                        <small>{{ package.uuid }}</small>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'packages-index'})">{{ 'packages.packages' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'packages.package' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
        <div class="row mb-4">
            <div v-if="package.service" class="container col-12 mb-2">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                    <h3>{{ 'packages.details' | trans}}</h3>
                    <p>{{ 'packages.name' | trans }}: <strong>{{ package.name ?? '-' }}</strong></p>
                    <p>{{ 'packages.created_at' | trans }}: {{ new Date(package.created_at).toLocaleString() }}</p>
                    <h3>{{ 'packages.service' | trans }}:</h3>
                    <p>{{ 'services.name' | trans }}: <strong>{{ package.service.name }}</strong></p>
                    <p>{{ 'services.type' | trans }}: <strong>{{ 'services.' + toCamelCase(package.service.type_name) | trans }}</strong></p>
                    <p><strong>{{ 'services.insertion_instructions' | trans }}</strong>: {{ 'services.' + toCamelCase(package.service.type_name) + '_insertion_description' | trans }}</p>
                </div>
            </div>
            <div :class="package.origin_address ? 'container col-md-6 col-sm-12 mb-2' : 'container col-md-12 col-sm-12 mb-2'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                    <h3>{{ 'payments.payment' | trans }}: </h3>
                    <span v-for="payment in payments">
                        <p style="font-size: 1.2rem">{{ 'payments.amount' | trans }}: <strong>{{ payment.amount }}</strong></p>
                        <p>{{ 'payments.status' | trans }}: <span :class="'badge ' + payment.status_name">{{ 'payments.' + payment.status_name | trans }}</span></p>
                        <p>{{ 'payments.type' | trans }}: <strong>{{ 'payments.' + payment.type_name | trans }}</strong></p>
                        <router-link v-if="payment.status_name == 'STATUS_PENDING'" :to="{ name: 'payments-show', params: { uuid: payment.uuid }}" class="btn btn-primary">{{ 'payments.process' | trans }}</router-link>
                        <hr v-if="payments.length > 1">
                    </span>
                    <p v-if="!payments"> {{ 'payment.noPayment' | trans }}</p>
                </div>
            </div>
            <div v-if="package.destination" :class="package.origin_address ? 'container col-md-6 col-sm-12 mb-2' : 'container col-md-12 col-sm-12 mb-2'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">   
                    <h3 v-if="package.service.type == 3">{{ 'machines.machine_storage' | trans }}: </h3>   
                    <h3 v-else>{{ 'machines.destination' | trans }}: </h3>
                    <p>{{ 'machines.name' | trans }}: {{ package.destination.name }}</p>
                    <p>{{ 'machines.opening_hours' | trans }}: {{ package.destination.opening_hours }}</p>
                    <p style="font-size: 1.2rem">{{ 'machines.localization' | trans }}: <strong>{{ package.destination.city }}, {{ package.destination.street }}, {{ package.destination.building_number }}</strong></p>
                </div>
            </div>
            <div v-if="package.destination_address" :class="package.origin_address ? 'container col-md-6 col-sm-12 mb-2' : 'container col-md-12 col-sm-12 mb-2'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">      
                    <h3>{{ 'packages.destination_address' | trans }}: </h3>
                    <p v-if="package.destination_address.province">{{ package.destination_address.country.name }}, {{ package.destination_address.province.name }}, {{ package.destination_address.city }}</p>
                    <p v-else>{{ package.destination_address.country.name }}, {{ package.destination_address.city }}</p>
                    <p style="font-size: 1.2rem">{{ package.destination_address.street }} {{ package.destination_address.building_number }}/{{ package.destination_address.apartment_number }}</p>
                </div>
            </div>
            <div v-if="package.origin_address" :class="package.origin_address ? 'container col-md-6 col-sm-12 mb-2' : 'container col-md-12 col-sm-12 mb-2'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">      
                    <h3>{{ 'packages.origin_address' | trans }}: </h3>
                    <p v-if="package.origin_address.province">{{ package.origin_address.country.name }}, {{ package.origin_address.province.name }}, {{ package.origin_address.city }}</p>
                    <p v-else>{{ package.origin_address.country.name }}, {{ package.origin_address.city }}</p>
                    <p style="font-size: 1.2rem">{{ package.origin_address.street }} {{ package.origin_address.building_number }}/{{ package.origin_address.apartment_number }}</p>
                </div>
            </div>
            <div :class="package.origin_address ? 'container col-md-6 col-sm-12 mb-2' : 'container col-md-12 col-sm-12 mb-2'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                    <h3>{{ 'packages.sender' | trans }}: </h3>
                    <p v-if="package.sender.name">{{ package.sender.name }}</p>
                    <p>{{ 'entities.phone' | trans }} : {{ package.sender.phone }}</p>
                    <h3>{{ 'packages.recipient' | trans }}: </h3>
                    <p v-if="package.recipient.name">{{ package.recipient.name }}</p>
                    <p style="font-size: 1.2rem">{{ 'entities.phone' | trans }}: <strong>{{ package.recipient.phone }}</strong></p>
                </div>
            </div>
        </div>
            <search-component model="parcels" header="parcels.parcels" :params="{ 'filter[package_uuid]' : package.uuid }" :includeCollapse="includeCollapse">
                <template v-slot:labels>
                    <th>{{ 'parcels.name' | trans }}</th>
                    <th>{{ 'parcels.gauge' | trans }}</th>
                    <th>{{ 'parcels.weight' | trans }} (kg)</th>
                    <th>{{ 'parcels.status' | trans }}</th>
                    <th>{{ 'parcels.code' | trans }}</th>
                </template>
                <template v-slot:columns="slotProps">
                    <td>{{ slotProps.element.name }}</td>
                    <td>{{ slotProps.element.gauge }}</td>
                    <td>{{ slotProps.element.weight }}</td>
                    <td><span :class="'badge ' + slotProps.element.status[0].name">{{ slotProps.element.status[0].name | trans}}</span></td>
                    <td><strong>{{ slotProps.element.insertion_code.code }}</strong></td>
                </template>
                <template v-slot:collapse="slotProps">
                    <div class="container pt-4 pb-0 px-4">
                        <div class="row">
                            <div class="col-3">
                                <h4>{{ 'parcels.statuses_history' | trans }}</h4>
                                <timeline-component :items="slotProps.element.status" @pickRow="changeDescription"/>
                            </div>
                            <div class="card-body p-4 col-9" v-if="statusDescription">
                                <p>{{ statusDescription.description }}</p>
                                <p>{{ statusDescription.created_at }}</p>                                 
                            </div>    
                        </div>
                    </div>
                </template>
            </search-component>
        </div>
    </div>
</template>

<script>
import descriptions from './status-descriptions.js';
import SearchComponent from '../../components/SearchComponent.vue';
import TimelineComponent from '../../components/TimelineComponent.vue';

export default {
    components: {
        SearchComponent,
        TimelineComponent,
    },
    data: function () {
        return {
            statusDescription: null,
            includeCollapse: true,
            package: null,
            payments: null,
        }
    },
    props: {
        uuid: null,
    },
    methods: {
         changeDescription: function(status) {
            self = this;
            this.statusDescription = null;
            var date = new Date(status.created_at );
            status.created_at = date.toLocaleString();

           if (status.name == "takenOut" || status.name == "emergencyTakenOut") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.takenOut;
            }
            else if (status.name == "awaitingInsert") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description =  descriptions.awaitingInsert;
            }
            else if (status.name == "pickedUp") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.pickedUp;
            }
            else if (status.name == "awaitingReception") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.awaitingReception;
            }
            else if (status.name == "inMachine") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.inMachine;
            }
            else {
                self.statusDescription.description = description.error;
            }
        },
        loadPackage () {
            axios.get(this.$root.route('ajax.packages.show', this.uuid))
            .then(response => this.package = response.data.data);
        },
        loadPayment() {
            axios.get(this.$root.route('ajax.payments.index'), {
                params: {
                    'filter[package_uuid]': this.uuid,
                }
            })
            .then(response => {
                this.payments = response.data.data;
            })
            .catch(error => {
                console.error("Error loading payments:", error);
            });
        },
        toCamelCase(str) {
            return str.toLowerCase().replace(/_(.)/g, (_, letter) => letter.toUpperCase());
        }
    },
    mounted () {
        this.loadPackage();
        this.loadPayment();
         // Refresh payments every 10 seconds
        this.intervalId = setInterval(this.loadPayment, 10000);
    }
}
</script>
<style lang="scss" scoped>
@import 'resources/sass/_payment-statuses.scss';
@import 'resources/sass/_parcel-statuses.scss';
</style>