<template>
    <div>
        <!--Recipient-->
        <div
            class="content"
            v-if="progress == 1"
        >
            <hr>
            <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_recipient' | trans }}</h3>
            <div class="row">
                <div class="col-md-6 col-sm-12 mb-2">
                    <div class="card p-4">
                        <div
                            class="mb-3"
                            style="max-width: 100%;"
                        >
                            <label>{{ 'packages.recipient_phone' | trans }}</label>
                            <vue-tel-input
                                class="form-control"
                                @input="onInput"
                                v-model="phoneModel"
                            ></vue-tel-input>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 mb-2">
                    <div class="card p-4">
                        <div
                            class="mb-3"
                            style="max-width: 100%;"
                        >
                            <label for="">{{ 'packages.recipient_email' | trans }}</label>
                            <input
                                v-model="email"
                                type="text"
                                class="form-control"
                                :placeholder="'packages.email_optional' | trans"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="progressChange(-1)"
                    >{{ 'packages.back' | trans }}</button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-block mb-2 start-0"
                        :disabled="!(emailValid && phoneValid || email.length == 0 && phoneValid)"
                        v-bind:class="{ 'btn-warning': !(emailValid && phoneValid || email.length == 0 && phoneValid), 'btn-success': (emailValid && phoneValid || email.length == 0 && phoneValid) }"
                        style=""
                        v-on:click="progressChange(1)"
                    >{{ 'packages.next' | trans }}</button>
                </div>
            </div>
        </div>
        <!--Services-->
        <div
            class="content"
            v-if="progress == 2"
        >
            <hr>
            <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_service' | trans }}</h3>
            <div class="row">
                <div
                    class="col-md-4 col-sm-6"
                    v-for="item in services"
                >
                    <div
                        class="card bg-info p-4"
                        v-if="service && item.uuid == service.uuid"
                    >
                        <h4>{{ item.name }}</h4>
                        <p>{{ 'services.price' | trans}}: {{ item.price }}</p>
                        <p v-if="item.parcel_limit == 0">{{ 'services.parcel_limit' | trans}}: {{ 'services.none' |
                            trans}}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans}}: {{ item.parcel_limit }}</p>
                    </div>
                    <div
                        class="card p-4"
                        v-on:click="chooseService(item)"
                        v-else
                        style="cursor: pointer"
                    >
                        <h4>{{ item.name }}</h4>
                        <p>{{ 'services.price' | trans}}: {{ item.price }}</p>
                        <p v-if="item.parcel_limit == 0">{{ 'services.parcel_limit' | trans}}: {{ 'services.none' |
                            trans}}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans}}: {{ item.parcel_limit }}</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="progressChange(-1)"
                    >{{ 'packages.back' | trans }}</button>
                </div>
                <div class="col-4 mt-4">

                </div>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-block mb-2 start-0"
                        :disabled="!service"
                        v-bind:class="{ 'btn-warning': !service, 'btn-success': service }"
                        style=""
                        v-on:click="progressChange(1)"
                    >{{ 'packages.next' | trans }}</button>
                </div>
            </div>
        </div>
        <!--Machines-->
        <div
            class="content"
            v-if="progress == 3"
        >
            <hr>
            <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_machines' | trans }}</h3>
            <p>{{ 'packages.origin_optional' | trans }}</p>
            <div class="row">
                <div class="col-md-5 col-sm-4">
                    <div class="card p-4">
                        <div class="mb-3">
                            <label for="">{{ 'machines.origin' | trans }}</label>
                            <machine-picker-component
                                :service_origin="service"
                                :selectedMachine="origin"
                                v-model="origin"
                                name="origin-modal"
                                :disabled="!service"
                            ></machine-picker-component>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-2 col-sm-4 rotate-xs"
                    style="text-align: center;"
                >
                    <span style="font-size: 6em;">
                        <font-awesome-icon icon="fa-solid fa-long-arrow-alt-right" />
                    </span>
                </div>
                <div class="col-md-5 col-sm-4">
                    <div class="card p-4">
                        <div class="mb-3">
                            <label for="">{{ 'machines.destination' | trans }}</label>
                            <machine-picker-component
                                :selectedMachine="destination"
                                :service_destination="service"
                                v-model="destination"
                                name="destination-modal"
                            ></machine-picker-component>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="progressChange(-1)"
                    >{{ 'packages.back' | trans }}</button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-block mb-2 start-0"
                        :disabled="!destination || !origin"
                        v-bind:class="{ 'btn-warning': (!destination || !origin), 'btn-success': (destination && origin) }"
                        style=""
                        v-on:click="progressChange(1)"
                    >{{ 'packages.next' | trans }}</button>
                </div>
            </div>
        </div>
        <!--Parcels-->
        <div
            class="content"
            v-if="progress == 4"
        >
            <hr>
            <h3 class="m-0 p-4 text-dark">{{ 'packages.add_parcels' | trans }}</h3>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="card p-4">
                        <h4>{{ 'services.service' | trans}}</h4>
                        <p>{{ service.name }}</p>
                        <p>{{ 'services.price' | trans}}: {{ service.price }}</p>
                        <p v-if="service.parcel_limit == 0">{{ 'services.parcel_limit' | trans}}: {{ 'services.none' |
                            trans}}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans}}: {{ service.parcel_limit }}</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <button
                        type="button"
                        class="btn btn-info btn-outline btn-block mb-2"
                        v-on:click="createParcel()"
                    >{{ 'parcels.add_parcel' | trans }} <font-awesome-icon icon="fa-solid fa-plus" /></button>
                    <div
                        class="container p-0 m-0 "
                        style="min-height: 25rem;"
                    >
                        <div
                            class="accordion"
                            role="tablist"
                        >
                            <div
                                class="card mb-1"
                                v-for="(element, index) in parcels"
                            >
                                <div
                                    header-tag="header"
                                    class="p-0"
                                    role="tab"
                                >
                                    <div class="container">
                                        <div class="row g-2 align-items-center">
                                            <button
                                                class="btn btn-outline-info col"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                :data-bs-target="'#' + _uid + 'accordion' + index"
                                                aria-expanded="false"
                                            >
                                                {{ 'parcels.parcel' | trans }} {{ index +1 }} 
                                                <font-awesome-icon
                                                    v-if="element.name && element.weight && element.gauge"
                                                    icon="fa-solid fa-check-circle"
                                                />
                                                <font-awesome-icon
                                                    v-else
                                                    icon="fa-solid fa-exclamation-circle"
                                                />
                                            </button>
                                            <button
                                                class="btn btn-warning col-auto"
                                                @click="deleteParcel(index)"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-trash-alt" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="collapse"
                                    :id="_uid + 'accordion' + index"
                                >
                                    <div class="card-body">
                                        <div class="d-inline-block">
                                            <div class="mb-3">
                                                <label for="">{{ 'parcels.name' | trans }}</label>
                                                <input
                                                    v-model="element.name"
                                                    type="text"
                                                    class="form-control"
                                                >
                                            </div>
                                            <div class="mb-3">
                                                <div style="display: inline-block; position: relative;">
                                                    <label for="">{{ 'parcels.weight' | trans }} (kg)</label>
                                                    <the-mask
                                                        @change.native="weightChange(element)"
                                                        class="form-control"
                                                        v-model="element.weight"
                                                        :mask="['YYYYYN']"
                                                        :tokens="customTokens"
                                                        type="text"
                                                    ></the-mask>
                                                    <!--<input type="number"  v-model="element.weight" class="form-control">-->
                                                    <span id="kg-suffix">kg</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <h4 style="text-align: center;">{{ 'parcels.gauges' | trans }}</h4>
                                        <div class="row">
                                            <div
                                                class="col-md-4 col-sm-12 mb-2"
                                                v-for="item in gauges"
                                            >
                                                <div
                                                    class="card bg-info p-4"
                                                    v-if="element.gauge_uuid == item.uuid"
                                                >
                                                    <h4>{{ item.name }}</h4>
                                                    <p>{{ 'parcels.gauge_price' | trans }}: {{ item.price }}</p>
                                                    <p>{{ 'parcels.gauge_max_weight' | trans }}: {{ item.max_weight }}kg
                                                    </p>
                                                </div>
                                                <div
                                                    class="card p-4"
                                                    v-else
                                                    v-on:click="changeParcelGauge(element, item)"
                                                    style="cursor: pointer"
                                                >
                                                    <h4>{{ item.name }}</h4>
                                                    <p>{{ 'parcels.gauge_price' | trans }}: {{ item.price }}</p>
                                                    <p>{{ 'parcels.gauge_max_weight' | trans }}: {{ item.max_weight }}kg
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="progressChange(-1)"
                    >{{ 'packages.back' | trans }}</button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-block mb-2 start-0"
                        :disabled="!parcelsValid || !parcels.length"
                        v-bind:class="{ 'btn-warning': !parcelsValid || !parcels.length, 'btn-success': parcelsValid && parcels.length }"
                        style=""
                        v-on:click="progressChange(1)"
                    >{{ 'packages.next' | trans }}</button>
                </div>
            </div>
        </div>
        <!--Payment-->
        <div
            class="content"
            v-if="progress == 5"
        >
            <hr>
            <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_payment' | trans }}</h3>
            <div class="row">
                <div class="col-md-4">
                    <div class="card p-4">
                        <h4>{{ 'payments.payment' | trans}}</h4>
                        <p>{{ 'services.service' | trans}}: {{ service.price }}</p>
                        <p>{{ 'parcels.parcels' | trans}}: {{ parcelsTotalPrice }}</p>
                        <h4>{{ 'payments.payment_summ' | trans }}: {{ totalPrice }}</h4>
                    </div>
                </div>
                <div
                    class="col-md-8"
                    style="text-align: center;"
                >
                    <div class="row">
                        <div class="container col-md-6 col-sm-12 mb-2">
                            <div
                                <div
                                class="card p-4 bg-info"
                                style="flex-wrap: wrap; height: 100%;"
                                v-if="paymentType=='1'"
                            >
                                <h3 style="text-align: center;">{{ 'payments.balance' | trans }}</h3>
                            </div>
                            <div
                                class="card p-4"
                                style="flex-wrap: wrap; height: 100%; cursor: pointer"
                                v-else
                                v-on:click="choosePaymentType(1)"
                            >
                                <h3 style="text-align: center;">{{ 'payments.balance' | trans }}</h3>
                            </div>
                        </div>
                        <div class="container col-md-6 col-sm-12 mb-2">
                            <div
                                <div
                                class="card p-4 bg-info"
                                style="flex-wrap: wrap; height: 100%;"
                                v-if="paymentType=='2'"
                            >
                                <h3 style="text-align: center;">{{ 'payments.transfer' | trans }}</h3>
                            </div>
                            <div
                                class="card p-4"
                                style="flex-wrap: wrap; height: 100%; cursor: pointer"
                                v-else
                                v-on:click="choosePaymentType(2)"
                            >
                                <h3 style="text-align: center;">{{ 'payments.transfer' | trans }}</h3>
                            </div>
                        </div>
                        <div class="container col-md-6 col-sm-12 mb-2">
                            <div
                                <div
                                class="card p-4 bg-info"
                                style="flex-wrap: wrap; height: 100%;"
                                v-if="paymentType=='3'"
                            >
                                <h3 style="text-align: center;">DotPay</h3>
                            </div>
                            <div
                                class="card p-4"
                                style="flex-wrap: wrap; height: 100%; cursor: pointer"
                                v-else
                                v-on:click="choosePaymentType(3)"
                            >
                                <h3 style="text-align: center;">DotPay</h3>
                            </div>
                        </div>
                        <div class="container col-md-6 col-sm-12 mb-2">
                            <div
                                <div
                                class="card p-4 bg-info"
                                style="flex-wrap: wrap; height: 100%;"
                                v-if="paymentType=='4'"
                            >
                                <h3 style="text-align: center;">{{ 'payments.machine' | trans }}</h3>
                            </div>
                            <div
                                class="card p-4"
                                style="flex-wrap: wrap; height: 100%; cursor: pointer"
                                v-else
                                v-on:click="choosePaymentType(4)"
                            >
                                <h3 style="text-align: center;">{{ 'payments.machine' | trans }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="progressChange(-1)"
                    >{{ 'packages.back' | trans }}</button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-block mb-2 start-0"
                        :disabled="paymentType == null"
                        v-bind:class="{ 'btn-warning': paymentType == null, 'btn-success': paymentType != null }"
                        style=""
                        v-on:click="progressChange(1)"
                    >{{ 'packages.next' | trans }}</button>
                </div>
            </div>
        </div>
        <!--Summary-->
        <div
            class="content"
            v-if="progress == 6"
        >
            <hr>
            <h3 class="m-0 p-4 text-dark">{{ 'packages.summary' | trans }}</h3>
            <div class="row row-cols-1 row-cols-md-2 g-4">
                <!-- Recipient Card -->
                <div class="col mb-4">
                    <div class="card p-4 h-100">
                        <h4 class="card-title">{{ 'packages.recipient' | trans }}</h4>
                        <p><strong>{{ 'entities.email' | trans }}:</strong> {{ email }}</p>
                        <p style="font-size: 1.5rem;"><strong>{{ 'entities.phone' | trans }}:</strong> {{ phone }}</p>
                    </div>
                </div>
                <!-- Service Card -->
                <div class="col mb-4">
                    <div class="card p-4 h-100">
                        <h4 class="card-title">{{ 'services.service' | trans }}</h4>
                        <p style="font-size: 1.5rem;"><strong>{{ 'services.name' | trans }}:</strong> {{ service.name }}</p>
                        <p><strong>{{ 'services.price' | trans }}:</strong> {{ service.price }}</p>
                        <p>
                            <strong>{{ 'services.parcel_limit' | trans }}:</strong>
                            <span v-if="service.parcel_limit == 0">{{ 'services.none' | trans }}</span>
                            <span v-else>{{ service.parcel_limit }}</span>
                        </p>
                    </div>
                </div>
                <!-- Route Card -->
                <div class="col mb-4">
                    <div class="card p-4 h-100">
                        <h4 class="card-title">{{ 'packages.route' | trans }}</h4>
                        <p v-if="origin">
                            <strong>{{ 'machines.origin' | trans }}:</strong> {{ origin.name }}
                        </p>
                        <p>
                            <strong>{{ 'machines.destination' | trans }}:</strong> {{ destination.name }}
                        </p>
                        <p class="mt-3" style="font-style: italic;">
                            {{ 'services.endToEnd_insertion_description' | trans }}
                        </p>
                    </div>
                </div>
                <!-- Payment Card -->
                <div class="col mb-4">
                    <div class="card p-4 h-100">
                        <h4 class="card-title">{{ 'payments.payment' | trans }}</h4>
                        <p>
                            <strong>{{ 'payments.method' | trans }}:</strong>
                            <span v-if="paymentType == 1">{{ 'payments.balance' | trans }}</span>
                            <span v-if="paymentType == 2">{{ 'payments.transfer' | trans }}</span>
                            <span v-if="paymentType == 3">DotPay</span>
                            <span v-if="paymentType == 4">{{ 'payments.machine' | trans }}</span>
                        </p>
                        <div v-if="service && parcelsTotalPrice !== undefined">
                            <hr>
                            <p><strong>{{ 'payments.service_price' | trans }}:</strong> {{ service.price }}</p>
                            <p><strong>{{ 'payments.parcels_total_price' | trans }}:</strong> {{ parcelsTotalPrice }}</p>
                            <p style="font-size: 1.5rem;"><strong>{{ 'payments.total' | trans }}:</strong> {{ totalPrice }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Parcels List -->
            <div class="row mt-4">
                <div class="col">
                    <div class="card p-4">
                        <h4 class="card-title">{{ 'packages.parcels' | trans }}</h4>
                        <div class="row row-cols-1 row-cols-md-3 g-4 mt-3">
                            <div
                                class="col"
                                v-for="item in parcels"
                                :key="item.index"
                            >
                                <div class="border p-2 h-100 d-flex flex-column justify-content-center">
                                    <p><strong>{{ 'parcels.name' | trans }}:</strong> {{ item.name }}</p>
                                    <p><strong>{{ 'parcels.weight' | trans }} (kg):</strong> {{ item.weight }}</p>
                                    <p><strong>{{ 'parcels.gauge' | trans }}:</strong> {{ item.gauge.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <hr>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="progressChange(-1)"
                    >{{ 'packages.back' | trans }}</button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-block mb-2 start-0"
                        style=""
                        v-on:click="sendPackage()"
                    >{{ 'packages.create' | trans }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { TheMask } from 'vue-the-mask'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import MachinePickerComponent from '../../pickers/MachinePickerComponent.vue';
import { VueTelInput } from 'vue-tel-input';

export default {
    components: {
        TheMask,
        MachinePickerComponent,
        VueTelInput,
    },
    data: function () {
        return {
            email: "",
            phoneValid: false,
            phoneModel: "",
            phone: "",
            service: null,
            services: null,
            origin: null,
            gauges: [],
            destination: null,
            parcels: [],
            index: 0,
            progress: 1,
            paymentType: null,
            serviceType: 4,
            parcelsTotalPrice: 0,
            totalPrice: 0,
            buttonSuccess: 'btn-success',
            buttonWarning: 'btn-warning',
            customTokens: {
                Y: {pattern: /[0-9.-.]/},
                N: {pattern: /[0-9]/}
            },
            test: /^[^\s@]+@[^\s@]+$/,
        }
    },

    computed: {
        emailValid() {
            return this.test.test(this.email);
        },
        parcelsValid () {
            if (this.parcels.find(element => element.name.length == 0) != undefined) return false;
            if (this.parcels.find(element => element.weight.length == 0) != undefined) return false;
            if (this.parcels.find(element => element.gauge_uuid.length == 0) != undefined) return false;
            return true;
        }
        
	},
    methods: {
        onInput: function(formattedNumber, phoneObject) {
            this.phoneValid = false;
            this.phone = phoneObject.number;
            if (phoneObject.valid == true) this.phoneValid = true;
        },
        weightChange: function(parcel) {
            if (parcel.gauge && parseFloat(parcel.weight) > parseFloat(parcel.gauge.max_weight)) {
                parcel.gauge_uuid = "";
                parcel.gauge = null;
                 Swal.fire({
                    icon: 'error',
                    title: this.$options.filters.trans('parcels.too_heavy'),
                })
            };
        },
        parcelsPrice: function() {
            this.parcelsTotalPrice = 0;
            for(var i = 0; i < this.parcels.length; i++) {
                this.parcelsTotalPrice += parseFloat(this.parcels[i].gauge.price);
            }
            this.totalPrice = parseFloat(this.service.price) + parseFloat(this.parcelsTotalPrice);
        },
        changeParcelGauge: function(parcel, gauge) {
            if(parseFloat(parcel.weight) > parseFloat(gauge.max_weight)) {
                Swal.fire({
                    icon: 'error',
                    title: this.$options.filters.trans('parcels.too_heavy'),
                })
            }
            else {
                parcel.gauge_uuid = gauge.uuid;
                parcel.gauge = gauge;
                this.parcelsPrice();
            }
        },
        chooseService: function(item) {
            this.service = item;
            this.origin = null;
            this.gauges = [];
            this.destination = null;
            this.payment_type = null;
            this.parcels = [];
            this.loadGauges();
        },
        progressChange: function(value) {
            this.progress += value;
            this.$emit('progress', this.progress);  
        },
        loadServices: function() {
            let self = this;
            axios.get(this.$root.route('ajax.services.index'), {
                params: {
                    'type': self.serviceType,
                }
                })
                .then(function (response) {
                    self.services = response.data.data;
                }) 
        },
        loadGauges: function() {
            let self = this;
            axios.get(this.$root.route('ajax.gauges.index'), {
                params: {
                    'filter[service_uuid]': self.service.uuid,
                    // filter[max_width] : element.width
                }
                })
                .then(function (response) {
                    self.gauges = response.data.data;
                })
        },
        sendPackage() {
            axios.post(this.$root.route("ajax.packages.store"), {
                email: this.email,
                phone: this.phone,
                service_uuid: this.service.uuid,
                destination_uuid: this.destination.uuid,
                parcels: this.parcels,
                payment_type: this.paymentType,
                payment_stage: 1,
            }).then((response) => {
                const paymentType = this.paymentType;
                const responseData = response.data;

                if ([1, 4].includes(paymentType)) {
                    Swal.fire({
                        icon: "success",
                        title: this.$options.filters.trans('packages.created'),
                        confirmButtonText: "OK",
                    }).then(() => {
                        this.$emit("packageCreated", paymentType, responseData);
                    });
                } else if ([2, 3].includes(paymentType)) {
                    Swal.fire({
                        icon: "info",
                        title: this.$options.filters.trans('payments.click_to_proceed'),
                        html: this.$options.filters.trans('payments.click_bellow_to_complete'),
                        showCancelButton: true,
                        confirmButtonText: this.$options.filters.trans('payments.proceed'),
                        cancelButtonText: this.$options.filters.trans('payments.cancel'),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$emit("packageCreated", paymentType, responseData);
                        }
                    });
                }
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            });
        },
        createParcel: function() {
            if (this.service.parcel_limit != 0) {
                if (this.parcels.length < this.service.parcel_limit) {
                    var obj = {
                        name: "Parcel",
                        weight: "",
                        gauge_uuid: "",
                        gauge: null,
                        index: this.index,
                    };
                    this.parcels.push(obj);
                    this.index++;
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: this.$options.filters.trans('parcels.limit_reached'),
                    })
                }
            } 
            else {
                var obj = {
                    name: "Parcel",
                    weight: "",
                    gauge_uuid: "",
                    gauge: null,
                    index: this.index,
                };
                this.parcels.push(obj);
                this.index++;
            }  
        },
        deleteParcel: function(parcelIndex) {
            this.parcels.splice(parcelIndex, 1)
        },

        choosePaymentType: function(payment) {
            this.paymentType = payment;
        },
    },
    watch: {
        service: function() {
            this.parcels = [];
        },
    },
    mounted() {
        this.loadServices();
    } 
}
</script>

<style scoped>
#kg-suffix {
    position: absolute;
    left: 100%;
    top: 55%;
    color: #555;
    padding-left: 5px;
    font: inherit;
}
@media (max-width: 575.98px) { /* Bootstrap's xs breakpoint */
  .rotate-xs {
    transform: rotate(90deg); /* Rotate by 90 degrees */
  }
}
</style>